import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, getParamTable } from '../../../../lib/Helper';

import { Table, Row, Col, Divider, PageHeader, Input, Tooltip } from 'antd';
import {  InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';

class DataPelanggaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [{
                    field: "id",
                    sort: "DESC",
                    urutan: 1
                }],
                filtering: {
                    tahun_ajaran: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            ddl: {
                tahunAjaran: []
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getTahunAjaranDDL()
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran/tahun", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/pelanggaran/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };


    render() {
        const { table } = this.state;

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Data Pelanggaran"
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} >
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Kode',
                                    dataIndex: 'kode',
                                },
                                {
                                    title: 'Aspek',
                                    dataIndex: 'aspek',
                                },
                                {
                                    title: 'Pelanggaran',
                                    dataIndex: 'pelanggaran',
                                },
                                {
                                    title: 'Poin',
                                    dataIndex: 'poin',
                                },
                                {
                                    title: 'Sanksi',
                                    dataIndex: 'sanksi',
                                }
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DataPelanggaran);
