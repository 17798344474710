import React from 'react';
import { PageHeader, Breadcrumb, Select, Divider, Row, Col, Form } from 'antd';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { DoubleRightOutlined } from '@ant-design/icons';

import { _setAxios } from "../../lib/Helper"

import "./style.css"
import Detail from './modal/Detail';
import moment from 'moment';
import { jenisAgendaOptions, statusAbsensiOptions } from '../../data/options';

export default class calendarIndex extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            data: [],
            detail: {
                data: {},
                visible: false
            },
            filter: {
                status_absensi: null,
                jenis_agenda: null
            }
        };
    }

    componentDidMount() {
        this.getAgenda(this.state.filter)
    }

    modalDetail = (visible = false, data = {}) => {
        this.setState({
            detail: {
                data: data,
                visible: visible
            },
        })
    }

    handleChange = (name, val) => {
        let filter = {
            ...this.state.filter,
            [name]: val
        }
        this.setState({
            filter: filter
        })

        this.getAgenda(filter)
    }

    getAgenda = (filter) => {
        _setAxios("kaldik-kegiatan/kaldik/agenda", "POST", filter).then(resp => {
            if (resp.status === true) {
                let events = []
                let data = []
                resp.data.data.forEach(el => {
                    var endDate = moment(el.tgl_akhir, "YYYY-MM-DD").add('days', 1);

                    console.log("endDate", endDate.format("YYYY-MM-DD"))

                    events.push({
                        id: el.id,
                        title: el.judul,
                        start: el.tgl_awal,
                        end: endDate.format("YYYY-MM-DD"),
                        status_absensi: el.status_absensi
                    })

                    data[el.id] = el
                });
                this.setState({
                    events: events,
                    data: data
                });
            }
        })
    }

    render() {
        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kalender & Kegiatan</Breadcrumb.Item>
                        <Breadcrumb.Item>Kalender</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Form
                        layout="vertical"
                    >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={3}>
                                <Form.Item label="Status Absensi" name="status">
                                    <Select
                                        allowClear
                                        placeholder="Filter status absensi"
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(val) => {
                                            this.handleChange("status", val)
                                        }}
                                        options={statusAbsensiOptions}
                                    />
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={4}>
                                <Form.Item label="Jenis Agenda" name="jenis_agenda">
                                    <Select
                                        allowClear
                                        placeholder="Filter jenis agenda"
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(val) => {
                                            this.handleChange("jenis_agenda", val)

                                        }}
                                        options={jenisAgendaOptions}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>


                    <Divider />
                    <FullCalendar
                        defaultView="dayGridMonth"
                        themeSystem="Simplex"
                        // header={{
                        //     left: "prev,next",
                        //     center: "title",
                        //     right: "dayGridMonth,timeGridMonth,timeGridDay",
                        // }}
                        plugins={[dayGridPlugin]}
                        events={this.state.events}
                        displayEventEnd="true"
                        // eventColor={"#" + Math.floor(Math.random() * 16777215).toString(16)}
                        eventContent={(eventInfo) => {
                            let data = this.state.data[eventInfo.event.id]
                            let bgColor = 'li-event-blue'
                            if (data.status_absensi === "Libur") {
                                bgColor = "li-event-red"
                            }
                            return <li className={`li-event ${bgColor}`} key={eventInfo.event.id} onClick={this.modalDetail.bind(this, true, data)}>
                                <>{eventInfo.event.title}</>
                            </li>
                        }}

                    />
                </div>

                {
                    this.state.detail.visible ?
                        <Detail
                            data={this.state.detail.data}
                            visible={this.state.detail.visible}
                            hideModal={this.modalDetail.bind(this, false)}
                        /> : ""
                }
            </>
        )
    }
}