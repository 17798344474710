import React from 'react';
import { _setAxios } from '../../lib/Helper';
import { Row, Col, List, Skeleton, Table } from 'antd';
import { connect } from 'react-redux'

class JadwalPelajaran extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      jadwal: {
        loading: false,
        header: {
          data1: [
            {
              title: "Tahun Ajaran",
              description: "-"
            },
            {
              title: "Jenis Rombel",
              description: "-"
            },
            {
              title: "Kurikulum",
              description: "-"
            },
            {
              title: "Tingkat / Jurusan",
              description: "-"
            },
          ],
          data2: [
            {
              title: "Nama Rombel",
              description: "-"
            },
            {
              title: "Wali Kelas",
              description: "-"
            },
            {
              title: "Kelas",
              description: "-"
            }
          ]
        },
        table: [],
      }
    };
  }

  getJadwalPelajaran = () => {
    this.setState({
      jadwal: {
        ...this.state.jadwal,
        loading: true
      }
    });
    _setAxios("jadwal/pelajaran-siswa", "GET").then(resp => {
      if (resp.status === true && resp.data.data.header) {
        const data = resp.data.data
        const header = data.header

        const gelarDepan = header.gelar_depan ? header.gelar_depan : ""
        const nama = header.nama_lengkap ? header.nama_lengkap : "-"
        const gelarBelakang = header.gelar_belakang ? header.gelar_belakang : ""
        const namaLengkap = gelarDepan + nama + gelarBelakang

        this.setState({
          jadwal: {
            ...this.state.jadwal,
            loading: false,
            header: {
              data1: [
                {
                  title: "Tahun Ajaran",
                  description: header.thn_awal + " - " + header.thn_akhir + " " + header.tipe_semester,
                },
                {
                  title: "Jenis Rombel",
                  description: header.jenis_rombel ? header.jenis_rombel : "-",
                },
                {
                  title: "Kurikulum",
                  description: header.kurikulum_nama ? header.kurikulum_nama : "-",
                },
                {
                  title: "Tingkat / Jurusan",
                  description: (header.tingkatan_kelas ? header.tingkatan_kelas : "-") + " / " + (header.jurusan_nama ? header.jurusan_nama : "-"),
                },
              ],
              data2: [
                {
                  title: "Nama Rombel",
                  description: header.rombel_nama ? header.rombel_nama : "-",
                },
                {
                  title: "Wali Kelas",
                  description: namaLengkap,
                },
                {
                  title: "Kelas",
                  description: header.ruangan_nama ? header.ruangan_nama : "-",
                },
              ],
            },
            table: data.table
          }
        });
      } else {
        this.setState({
          jadwal: {
            ...this.state.jadwal,
            loading: false
          }
        });
      }
    })
  }

  componentDidMount() {
    this.getJadwalPelajaran()
  }

  render() {
    const { jadwal } = this.state
    if (jadwal.loading) {
      return <Skeleton.Input active={true} className="skeleton-statistik" />
    } else {
      return (
        <div className="dashboard-column" style={{
          borderColor: "#36CFC9"
        }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <h5>Jadwal Pelajaran</h5>
              <div>Belajar yang semangat ya! <span role="img" aria-label="ok">👌</span></div>
            </Col>
            <Col xs={12}>
              <List
                itemLayout="horizontal"
                dataSource={jadwal.header.data1}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col xs={12}>
              <List
                itemLayout="horizontal"
                dataSource={jadwal.header.data2}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col xs={24}>
              <Table columns={columns} dataSource={jadwal.table} scroll={{ x: 1300 }} pagination={false} />
            </Col>
          </Row>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(JadwalPelajaran)

const columns = [
  // {
  //   title: 'Jam Pelajaran',
  //   width: 200,
  //   dataIndex: 'jam',
  //   key: 'jam',
  //   fixed: 'left',
  // },
  {
    title: 'Jam Ke-',
    dataIndex: 'jam_ke',
    fixed: 'left',
    width: '10%',
  },
  {
    title: 'Senin',
    dataIndex: 'Senin',
    render: (a, row) => row.Senin ? row.Senin : "-"

  },
  {
    title: 'Selasa',
    render: (a, row) => row.Selasa ? row.Selasa : "-"
  },
  {
    title: 'Rabu',
    render: (a, row) => row.Rabu ? row.Rabu : "-"
  },
  {
    title: 'Kamis',
    render: (a, row) => row.Kamis ? row.Kamis : "-"
  },
  {
    title: 'Jum\'at',
    render: (a, row) => row.Jumat ? row.Jumat : "-"
  },
  {
    title: 'Sabtu',
    render: (a, row) => row.Sabtu ? row.Sabtu : "-"
  },
  {
    title: 'Ahad',
    render: (a, row) => row.Minggu ? row.Minggu : "-"
  },
  // {
  //   title: 'Action',
  //   key: 'operation',
  //   width: 100,
  //   render: () => <>-</>,
  // },
];

