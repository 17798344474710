import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, getParamTable } from '../../../lib/Helper';

import { Image, Row, Col, Divider, Input, Tooltip, Empty, Button, message } from 'antd';
import { InfoCircleOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { noImage } from '../../../data/image';
import Preload from '../../../components/preload/PreloadContent'

import "./style.css"

class JalurMasukKedinasan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 1000,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Kedinasan"
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("bk/media-informasi/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    clickLink = (row) => {
        if (row.link === "" || row.link === "/" || row.link === "#") {
            message.warning('Tidak ada situs yg bisa di akses atau link tidak sesuai');
        } else {
            window.open(row.link);
        }
    }

    render() {
        const imageList = []
        const { table } = this.state;

        (table.data ? table.data : []).forEach((row, i) => {
            imageList.push(<Col xs={24} sm={6} key={i} style={{ textAlign: "center" }} className="gallery-image">
                <Row>
                    <Col xs={24}>
                        <Image src={row.file ? row.main_path + row.file : noImage} title={row.nama} />
                    </Col>
                    <Col xs={24} style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        padding: "24px, 0px"
                    }} title={row.nama} ><Button type="link" onClick={this.clickLink.bind(this, row)} ><LinkOutlined />{row.nama}</Button></Col>
                </Row>
            </Col>)
        });

        const dataEmpty = <Col xs={24}><Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    <h3>Data Jalur Masuk Kedinasan</h3>
                    <p>Tidak ada data sama sekali untuk di tampilkan</p>
                </span>
            }
        >
        </Empty></Col>

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row gutter={[24, 24]}>
                    {table.loading ? <Col xs={24}><Preload /></Col> : (imageList.length === 0 ? dataEmpty : imageList)}
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(JalurMasukKedinasan);
