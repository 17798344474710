import React from 'react';
import { Modal, Button, Select, Form, Row, Col, DatePicker, message } from 'antd';
import { _getDate, _setAxios } from '../../../../lib/Helper';
import { statusKetidakhadiranOptions } from '../../../../data/options';

const { Option } = Select;
const { RangePicker } = DatePicker;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                rombel: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getRombelDDL = (keyworld = "") => {
        _setAxios("rombel/dropdown", "POST", {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            search: keyworld,
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.list
                    },
                })
            }
        })
    }

    componentDidMount() {
        this.getRombelDDL()
    }

    render() {

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Status">
                                <Select
                                    allowClear
                                    name="status"
                                    placeholder="Status"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {statusKetidakhadiranOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Rombongan belajar">
                                <Select
                                    showSearch
                                    placeholder="Rombongan belajar"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={(value) => {
                                        this.getRombelDDL(value)
                                    }}
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("rombel_id", newValue ? newValue.value : null)
                                    }}
                                    notFoundContent={null}
                                    options={(this.state.ddl.rombel || []).map((d) => ({
                                        value: d.id,
                                        label: d.nama,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Periode">
                                <RangePicker
                                    // showTime
                                    style={{ width: '100%' }}
                                    onChange={(e, newValue) => {
                                        if (newValue.length === 2) {
                                            let tglAwal = _getDate(newValue[0])
                                            let tglAkhir = _getDate(newValue[1])

                                            let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
                                            let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

                                            if (_tglAwal > _tglAkhir) {
                                                return message.warning("Periode yang dimasukkan tidak valid.");
                                            }

                                            this.props.setTempTableFilter("tgl_awal", newValue[0])
                                            this.props.setTempTableFilter("tgl_akhir", newValue[1])

                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
