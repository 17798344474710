import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(tahunAjaran) {

  if (!tahunAjaran) {
    return []
  }

  return await _setAxios("keuangan/pemasukan/detail/dropdown", "POST", {
    tahun_ajaran: tahunAjaran,
  })
    .then(body => {
      let results = []
      body.data.data.forEach(row => {
        results.push({
          label: row.pos_pemasukan_nama,
          value: row.pemasukan_detail_id,
          nominal_tagihan: row.nominal
        })
      });

      return results
    })
}

const SelectBiaya = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList(props.thnAjaran).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [props.thnAjaran]);

  return (
    <Select
      {...props}
      fetchOptions={fetchList}
      placeholder="Pilih biaya"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectBiaya;
