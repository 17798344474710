import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb } from 'antd';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/redirect/AuthRedirect'
import Detail from '../modal/Detail';
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktopWithFilter from '../../../../components/sorting-table/SortingTableDesktopWithFilter';
import SortingTableMobileWithFilter from '../../../../components/sorting-table/SortingTableMobileWithFilter';
import SearchTable from '../../../../components/search-table/SearchTable';
import { DoubleRightOutlined } from '@ant-design/icons';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "tgl_masuk",
  label: "Tanggal Masuk",
}, {
  value: "tgl_pulang",
  label: "Tanggal Pulang",
}]

class KehadiranSiswa extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          jenis: "Siswa",
        },
      },
      filter: {
        values: {
          status: null,
        },
        visible: false,
      },
      detail: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isNew ? 1 : params.pagination.current
      }
    }
    _setAxios("presensi/absensi/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }


  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: {
          ...data,
          ruangan_id: data.ruangan_id ? {
            id: data.ruangan_id,
            nama: data.ruangan_nama
          } : null
        },
        visible: visible
      },
    })
  }

  modalDetail = (visible = false, data = {}) => {
    this.setState({
      detail: {
        data: data,
        visible: visible
      },
    })
  }

  render() {

    const access = this.props.privilege.access["/presensi/kehadiran"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Presensi</Breadcrumb.Item>
            <Breadcrumb.Item>Kehadiran</Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

          <PageHeader
            className="site-page-header"
            title="Siswa"
            // subTitle="Data kehadiran siswa"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </MobileView>
            </Col>

          </Row>

          {
            this.state.filter.visible ?
              <Filter
                visible={this.state.filter.visible}
                hideModal={this.modalFilter.bind(this, false)}
                setTempTableFilter={this.setTempTableFilter}
                setTableFilterFromTemp={this.setTableFilterFromTemp}
              /> : ""
          }
          {
            this.state.detail.visible ?
              <Detail
                data={this.state.detail.data}
                visible={this.state.detail.visible}
                hideModal={this.modalDetail.bind(this, false)}
                fetchTable={this.fetchTable}
              /> : ""
          }
          <BackTop />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(KehadiranSiswa);
