import React from 'react';
import { Row, Col, Divider, Skeleton } from 'antd';
import { connect } from 'react-redux'
import { _setAxios } from '../../lib/Helper';

class Point extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      total: {
        pelanggaran: 0,
        pointPelanggaran: 0,
      },
    };
  }

  getTotal = () => {
    _setAxios("tatibsi/dashboard/label-header-eksternal", "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          loading: false,
          total: {
            pelanggaran: resp.data.data ? resp.data.data.total_pelanggaran : 0,
            pointPelanggaran: resp.data.data ? resp.data.data.total_poin : 0,
          }
        });
      }else{
        this.setState({
          loading: false,
        });
      }
    })
  }

  componentDidMount() {
    this.getTotal()
  }

  render() {
    const { total, loading } = this.state
    if (loading) {
      return <Skeleton.Input active={true} className="skeleton-statistik" />
    } else {
      return (
        <div className="dashboard-column" style={{
          minHeight: 210,
          borderColor: "#FF4D4E",
        }}>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <div style={{
                fontSize: 38,
                fontWeight: 500
              }}>{total.pelanggaran}</div>
              <div style={{
                color: "#9C9C9C",
                fontSize: 14
              }}>Total Pelanggaran</div>
            </Col>
            <Col xs={12}>
              <div style={{
                fontSize: 38,
                fontWeight: 500
              }}>{total.pointPelanggaran}</div>
              <div style={{
                color: "#9C9C9C",
                fontSize: 14
              }}>Total Poin Pelanggaran</div>
            </Col>
            <Col xs={24}>
              <Divider />
              <div> <span role="img" aria-label="strong">💪</span> Pertahankan agar poin tetap rendah!.</div>
            </Col>
          </Row>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Point)