import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Pagination } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { _fmtRupiah, _tableLogActivity, getParamTable } from '../../../../lib/Helper';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Siswa',
                  dataIndex: 'siswa_nama',
                }, {
                  title: 'Tanggal Bayar',
                  dataIndex: 'tgl_transaksi',
                }, {
                  title: 'Tahun Ajaran',
                  dataIndex: 'tahun_ajaran',
                }, {
                  title: 'Biaya',
                  dataIndex: 'pos_pemasukan_nama',
                }, {
                  title: 'Nominal Tagihan',
                  render: (a, row) => "Rp. " + (row.nominal_tagihan ? _fmtRupiah(row.nominal_tagihan) : 0)
                }, {
                  title: 'Nominal Bayar',
                  render: (a, row) => "Rp. " + (row.nominal_pembayaran ? _fmtRupiah(row.nominal_pembayaran) : 0)
                }, {
                  title: 'Jenis Pembayaran',
                  dataIndex: 'jenis_pembayaran',
                }, {
                  title: 'File',
                  render: (_, row) => row.bukti_pembayaran ? <a rel="noopener noreferrer" target='_blank' href={row.main_path + row.bukti_pembayaran} style={{ fontSize: 11 }} >Download <DownloadOutlined /></a> : "-",
                }, {
                  title: 'Keterangan',
                  render: (_, row) => row.keterangan ? row.keterangan : "-"
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
