import React from 'react';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import Config from "./Config";
import { _getToken, _setAxios } from './lib/Helper';
import axios from 'axios';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.css';
import 'antd/dist/antd.css';

//Global
import HeaderLayout from './layouts/header/Header';
import Sidebar from './layouts/sidebar/Sidebar';
import Preload from './components/preload/Preload'
import Home from './pages/home/Home'
import Login from './pages/login/Login';

// Error Page
import Error403 from './pages/errors/403';
import Error404 from './pages/errors/404';

//Profile
import Profile from './pages/profile/Profile';

// Peserta didik
import SiswaDetail from './pages/peserta-didik/detail/SiswaDetail';
import AlumniDetail from './pages/alumni/AlumniDetail';

//Kesiswaan
import TataTertibSekolah from './pages/kesiswaan/tata-tertib-sekolah/TataTertibSekolah';
import PelanggaranSiswaDetail from './pages/kesiswaan/pelanggaran-siswa/PelanggaranSiswaDetail';
import PenghargaanSiswaDetail from './pages/kesiswaan/penghargaan-siswa/PenghargaanSiswaDetail';

//Presensi
import Ketidakhadiran from './pages/presensi/ketidakhadiran/list/index';
import Kehadiran from './pages/presensi/kehadiran/list/index';

//Bimbingan Konseling
import ProfilBk from './pages/bimbingan-konseling/profil-bk/ProfilBk';
import HasilTestPesertaDidikDetail from './pages/bimbingan-konseling/hasil-tes/HasilTestPesertaDidikDetail';
import BimbinganKonselingLaporan from './pages/bimbingan-konseling/laporan/Laporan';
import BimbinganKonselingLaporanDetail from './pages/bimbingan-konseling/laporan/LaporanDetail';
import MediaInformasi from './pages/bimbingan-konseling/media-informasi/MediaInformasi';
import Konsultasi from './pages/bimbingan-konseling/konsultasi/Konsultasi';

// Kegiatan
import Ekstrakurikuler from './pages/kegiatan/ekstrakurikuler/list/index';
import Pramuka from './pages/kegiatan/pramuka/list/index';
import CalendarIndex from './pages/calendar/index';

//Keuangan
import PembayaranSiswa from './pages/keuangan/pembayaran-siswa/list/index';
import PembayaranSiswaTagihan from './pages/keuangan/pembayaran-siswa/tagihan/list/index';


import TabBar from './components/tabbar/TabBar';
const { Content, Footer } = Layout;
export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isAuth: false,
      privilege: {
        status: false,
        access: {}
      },
      menu: [],
      isMobile: false,
    }
  }

  componentWillMount() {
    console.disableYellowBox = true;
    this.checkAuth()
    this.getMenu()
  }

  checkAuth() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const pathname = window.location.pathname;
      _setAxios("privilege/access", "GET").then(resp => {
        if (resp.status === true) {
          if (pathname === "/login") {
            window.location.href = Config.base_url
          } else {
            if (resp.data.data.profile_ex && resp.data.data.profile_ex.siswa_akun_ditangguhkan === 1) {
              resp.data.data.access = {
                "/": {
                  path: "/",
                  status: true,
                  action: {
                    view: true
                  }
                }
              }
              // resp.data.data.profile_ex.siswa_alasan_akun_ditangguhkan = "Yaaah kamu gak bisa login yaah kasihan"
            }

            this.setState({
              loading: false,
              isAuth: true,
              privilege: resp.data.data
            })
          }
        } else {
          this.setState({
            loading: false,
            isAuth: false,
          })
        }
      })
    }
  }

  getMenu() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const headers = {
        'Accept': 'application/json',
        'Authorization': _getToken()
      };
      axios.get(Config.api + '/privilege/menu', { headers })
        .then((resp) => {
          if (this.state.privilege.profile_ex && this.state.privilege.profile_ex.siswa_akun_ditangguhkan === 1) {
            resp.data.data = [{
              icon: "icon-dashboard",
              name: "Dashboard",
              path: "/"
            }]
          }
          this.setState({ menu: resp.data.data });
        })
        .catch(function (error) {
          if (error.response) {
            // _error('topRight', 'Failed', error.response.data.message)
          }
        });
    }
  }

  render() {
    const { loading, privilege, menu, isMobile } = this.state

    const globalState = {
      privilege: privilege,
      menu: menu,
      menuCollapse: false,
      isMobile: isMobile
    }

    if (loading === true) {
      return <Preload />
    }
    //reducer
    const rootReducer = (state = globalState, action) => {
      switch (action.type) {
        case "AUTH":
          return {
            ...state,
            privilege: action.privilege,
            menu: action.menu
          }
        case "MENU":
          return {
            ...state,
            menuCollapse: action.menuCollapse,
          }
        case "SCREEN":
          return {
            ...state,
            isMobile: action.isMobile,
          }
        default:
          return state
      }
    }

    //store
    const storeRedux = createStore(rootReducer);

    return (
      <Provider store={storeRedux}>
        <Router>
          {
            this.state.isAuth === true ?
              <Layout style={{ minHeight: '100vh' }}>

                <Sidebar {...this.props} />
                <Layout className="site-layout">
                  <HeaderLayout {...this.props} />
                  <TabBar {...this.props} />

                  <Content>
                    <Switch>
                      <Route exact path="/login" component={(props) => <Redirect to="/" />} />
                      <Route exact path="/" component={(props) => <Home {...props} />} />
                      <Route exact path="/profile" component={(props) => <Profile {...props} />} />

                      <Route exact path="/peserta-didik/aktif" component={(props) => <SiswaDetail {...props} />} />
                      <Route exact path="/peserta-didik/alumni" component={(props) => <AlumniDetail {...props} />} />

                      <Route exact path="/kesiswaan/tata-tertib-sekolah" component={(props) => <TataTertibSekolah {...props} />} />
                      <Route exact path="/kesiswaan/pelanggaran-siswa" component={(props) => <PelanggaranSiswaDetail {...props} />} />
                      <Route exact path="/kesiswaan/penghargaan-siswa" component={(props) => <PenghargaanSiswaDetail {...props} />} />

                      <Route exact path="/bimbingan-konseling/profil-bk" component={(props) => <ProfilBk {...props} />} />
                      <Route exact path="/bimbingan-konseling/hasil-tes" component={(props) => <HasilTestPesertaDidikDetail {...props} />} />
                      
                      <Route exact path="/presensi/kehadiran" component={(props) => <Kehadiran {...props} />} />
                      <Route exact path="/presensi/ketidakhadiran" component={(props) => <Ketidakhadiran {...props} />} />

                      <Route exact path="/bimbingan-konseling/hasil-tes/detail/:id/:rombel_id" component={(props) => <HasilTestPesertaDidikDetail {...props} />} />
                      <Route exact path="/bimbingan-konseling/media-informasi" component={(props) => <MediaInformasi {...props} />} />
                      <Route exact path="/bimbingan-konseling/konsultasi" component={(props) => <Konsultasi {...props} />} />
                      <Route exact path="/bimbingan-konseling/laporan" component={(props) => <BimbinganKonselingLaporan {...props} />} />
                      <Route exact path="/bimbingan-konseling/laporan/detail/:rombel_id" component={(props) => <BimbinganKonselingLaporanDetail {...props} />} />
                   
                      <Route exact path="/ekstrakurikuler-pramuka/ekstrakurikuler" component={(props) => <Ekstrakurikuler {...props} />} />
                      <Route exact path="/ekstrakurikuler-pramuka/pramuka" component={(props) => <Pramuka {...props} />} />
                      <Route exact path="/kalender-kegiatan/kalender" component={(props) => <CalendarIndex {...props} />} />

                      {/* KEUANGAN */}
                      <Route key={14.4} exact path="/keuangan/pembayaran-siswa" component={(props) => <PembayaranSiswa {...props} />} />
                      <Route key={14.2} exact path="/keuangan/pembayaran-siswa/tagihan" component={(props) => <PembayaranSiswaTagihan {...props} />} />


                      <Route exact path="/403" component={(props) => <Error403 />} />
                      <Route component={Error404} />
                    </Switch>
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>Madrasah Digital MAN 1 Gresik @{new Date().getFullYear()}</Footer>
                </Layout>
              </Layout>
              :
              <>
                <Switch>
                  <Route exact path="/" component={(props) => <Redirect to="/login" />} />
                  <Route exact path="/login" component={(props) => <Login />} />
                  <Route component={Error404} />
                </Switch>
              </>
          }

        </Router>

      </Provider>
    );
  }
}